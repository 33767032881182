exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chirurg-dzieciecy-poznan-katarzyna-bernardczyk-js": () => import("./../../../src/pages/chirurg-dzieciecy-poznan-katarzyna-bernardczyk.js" /* webpackChunkName: "component---src-pages-chirurg-dzieciecy-poznan-katarzyna-bernardczyk-js" */),
  "component---src-pages-chirurg-onkolog-poznan-maria-czub-swierczek-js": () => import("./../../../src/pages/chirurg-onkolog-poznan-maria-czub-swierczek.js" /* webpackChunkName: "component---src-pages-chirurg-onkolog-poznan-maria-czub-swierczek-js" */),
  "component---src-pages-chirurg-poznan-witold-pracki-js": () => import("./../../../src/pages/chirurg-poznan-witold-pracki.js" /* webpackChunkName: "component---src-pages-chirurg-poznan-witold-pracki-js" */),
  "component---src-pages-dojazd-js": () => import("./../../../src/pages/dojazd.js" /* webpackChunkName: "component---src-pages-dojazd-js" */),
  "component---src-pages-ginekolog-poznan-grazyna-derezinska-kozlowska-js": () => import("./../../../src/pages/ginekolog-poznan-grazyna-derezinska-kozlowska.js" /* webpackChunkName: "component---src-pages-ginekolog-poznan-grazyna-derezinska-kozlowska-js" */),
  "component---src-pages-ginekolog-poznan-martyna-kozlowska-wytyk-js": () => import("./../../../src/pages/ginekolog-poznan-martyna-kozlowska-wytyk.js" /* webpackChunkName: "component---src-pages-ginekolog-poznan-martyna-kozlowska-wytyk-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kardiolog-poznan-joanna-galczynska-js": () => import("./../../../src/pages/kardiolog-poznan-joanna-galczynska.js" /* webpackChunkName: "component---src-pages-kardiolog-poznan-joanna-galczynska-js" */),
  "component---src-pages-okulista-poznan-walenty-chudzicki-js": () => import("./../../../src/pages/okulista-poznan-walenty-chudzicki.js" /* webpackChunkName: "component---src-pages-okulista-poznan-walenty-chudzicki-js" */)
}

